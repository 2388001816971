import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { AssetType } from 'common/types/view';
import CopyAssetModal from 'common/components/CopyAssetModal';

class CopyAssetModalDemo extends Component {
  render() {
    return (
      <CopyAssetModal
        assetTitle="My asset"
        onDismiss={() => alert('Dismissed!')}
        assetType={ AssetType.Measure }
        onCopy={() => alert('Copied!')} />
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(CopyAssetModalDemo),
    document.getElementById('component-demo')
  );
});
